// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-amp-js": () => import("./../../../src/pages/amp.js" /* webpackChunkName: "component---src-pages-amp-js" */),
  "component---src-pages-ask-the-experts-contact-js": () => import("./../../../src/pages/ask-the-experts-contact.js" /* webpackChunkName: "component---src-pages-ask-the-experts-contact-js" */),
  "component---src-pages-ask-the-experts-js": () => import("./../../../src/pages/ask-the-experts.js" /* webpackChunkName: "component---src-pages-ask-the-experts-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intl-guide-js": () => import("./../../../src/pages/intl-guide.js" /* webpackChunkName: "component---src-pages-intl-guide-js" */),
  "component---src-pages-metrics-js": () => import("./../../../src/pages/metrics.js" /* webpackChunkName: "component---src-pages-metrics-js" */),
  "component---src-pages-quickfacts-js": () => import("./../../../src/pages/quickfacts.js" /* webpackChunkName: "component---src-pages-quickfacts-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

